import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <h1 className="hero">CLEAR CHROMA</h1>
      <h2 className="subtitle">Coming Soon</h2>
    </div>
  );
}
